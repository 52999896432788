.actionButtonContainer {
  text-align: center;
  display: inherit;
}

.manualApproval {
  display: none;
  border-top: 2px dashed;
  text-align: center;
}

@media print {
  .actionButtonContainer {
    display: none;
  }

  .manualApproval {
    display: inherit;
  }
}

.title {
  text-decoration: underline;
}

.approvalFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.approvalField {
  margin-top: 80px;
  border-top: 1px solid;
  min-width: 100px;
}

.footnotes {
  font-size: 16px;
}
@media (max-width: 600px) {
  .footnotes {
    font-size: 13px;
  }
}

.contactInformation {
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #7b2c93;
  text-align: center;
}

.field {
  margin-inline-end: 4px;
  color: black;
  text-decoration: none;
  white-space: nowrap;
}

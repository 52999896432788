.fieldsContainer {
  display: flex;
  flex-direction: column;
}

.fieldsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.field {
  margin-bottom: 10px;
}

.error {
  margin-top: 25px;
  text-align: center;
  color: red;
}

.icon {
  text-align: left;
}

.iconImage {
  height: 150px;
}

@media (max-width: 600px) {
  .icon {
    padding-top: 20px;
    text-align: center;
  }

  .iconImage {
    height: 80px;
  }
}

.dateBox {
  padding-top: 20px;
  /*padding-left: 20px;*/
  text-align: left;
  display: block;
}

.subject {
  text-align: center;
  text-decoration: underline;
}

.contentTitle {
}

.content {
  display: block;
  margin-right: 20px;
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.costTitle {
  margin-top: 30px;
  text-decoration: underline;
}

.cost {
  white-space: pre-wrap;
}

.paymentConditions {
  white-space: pre-wrap;
}

.sumUp {
  text-align: left;
}

.approvalStatusBar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 50px;
  box-shadow:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.4);
  text-align: center;
}

@media (max-width: 600px) {
  .approvalStatusBar {
    font-size: 14px;
  }
}

.approvedBar {
  background-color: #4bb543;
}

.notApprovedBar {
  background-color: #fbdb05;
}

.pageNotFound {
  padding: 30px;
  text-align: center;

  font-size: 30px;
}

.linkField {
  margin-left: 10px;
}

.iconImage {
  height: 120px;
}
